import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import '@/plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/scss/main.scss'
import HighchartsVue from 'highcharts-vue'
import Amplify from 'aws-amplify'

store.dispatch('login/checkHealth');

Amplify.configure({
    VUE_APP_BASE_URL: 'VUE_APP_BASE_URL',
    VUE_APP_API_URL: 'VUE_APP_API_URL',
    VUE_APP_I18N_LOCALE: 'VUE_APP_I18N_LOCALE',
    VUE_APP_I18N_FALLBACK_LOCALE:'VUE_APP_I18N_FALLBACK_LOCALE',
    encodingType: 'ENCODING_TYPE',
    encryptionSecret: 'ENCRYPTION_SECRET',
    VUE_APP_AWS_ACCESS_KEY_ID: 'ACCESS_KEY_ID',
    VUE_APP_AWS_SECRET_ACCESS_KEY: 'SECRET_ACCESS_KEY',
    VUE_APP_AWS_DEFAULT_REGION: 'VUE_APP_AWS_DEFAULT_REGION',
    VUE_APP_AWS_SDK_LOAD_CONFIG: 1,
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(HighchartsVue)

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
