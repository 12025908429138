import api from '@/plugins/axios'
const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
	getRisks,
}

function getRisks(data) {
	return api.get('/risks?'+querystring.stringify(data),authHeader.classicalHeader())
}
