import api from '@/services/api/index.js'
import Vuex from "@/store";

const state = {
	sensors: [],
	loadingStatus: false,
	actualReports:[]
};


const mutations = {
	SET_SENSORS: (state, sensors) => {
		state.loadingStatus = true
		state.sensors = sensors;
		state.loadingStatus = false
	},
	BAD_REQUEST: (state, requestStatus) => {
		state.request = {status: requestStatus[1], message: requestStatus[0]}
	},
	SENSORS_IS_LOADING_STATUS: (state) => {
		state.loadingStatus = !state.loadingStatus
	},
	SET_DATA_FOR_SENSOR: (state, data) => {
		state.loadingStatus = true
		// let selectedSensor = state.sensors.find(sensor => sensor.id === data[0])
		// selectedSensor.dataReference.reports = data[1]
		state.actualReports = data[1]
		state.loadingStatus = false
	},
};
const actions = {
	setSensors: async (store,data) => {
		await api.sensor.getUserSensors(data)
			.then( response =>  {
				// if (currentUser.roles.includes('ROLE_ADMIN')){
				store.commit('SET_SENSORS', response.data.data.sensors);
				// } else {
				// 	store.commit('SET_SENSORS', response.data.data);
				// }
			})
			.catch(error => {
				if (error.response.status === 401) {
					Vuex.dispatch('login/logout')
				}
				store.commit('BAD_REQUEST', error);
			});
	},
	setData: async (store,data) => {
		await api.sensor.getSensorData(data)
			.then( response =>  {
				store.commit('SET_DATA_FOR_SENSOR', [data[0],response.data.data]);
			}).catch(error => {
				if (error.response.status === 401) {
					Vuex.dispatch('login/logout')
				}
				store.commit('BAD_REQUEST', error);
			});
	},
	emptySensors: async (store) => {
		store.commit('SET_SENSORS', {});
		store.commit('SENSORS_IS_LOADING_STATUS');
	},
};

const getters = {
	sensors: state => {
		return state.sensors
	},
	getSensorsById: (state) => (id) => {
		return state.sensors.find(sensor => sensor.serialNumber === id)
	},
	loadingStatus : state => {
		return state.loadingStatus
	},
	getRequest : state => {
		return state.request
	},
	actualReports: state => {
		return state.actualReports
	}
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
