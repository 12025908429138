<template>
    <div id="app">
        <div class="container-fluid">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        sendToLify(url) {
            let win = window.open(url, '_blank');
            win.focus();
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
