import api from '@/plugins/axios'
const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
	getUserSensors,
	getSensorData
}

function getUserSensors(data) {
	return api.get('/sensors?'+querystring.stringify(data),authHeader.classicalHeader())
}
function getSensorData(data) {
	return api.get('/sensors/'+data[0]+'/data?'+querystring.stringify(data[1]),authHeader.formDataHeader())
}