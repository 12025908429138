import SecureLS from '@/plugins/secureLS.js'

export default {
	classicalHeader,
	formDataHeader,
	unLoggedFormDataHeader
}

function classicalHeader() {
	const auth = SecureLS.get(btoa('token'));
	let headers = { headers:{
			'Accept': 'application/json',
			'Authorization': 'Bearer '+auth.token,
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	};

	if (auth && auth.token.length > 3) {
		return headers;
	} else {
		return {};
	}
}
function formDataHeader() {
	const auth = SecureLS.get(btoa('token'));
	let headers = { headers:{
			'Accept': 'application/json',
			'Authorization': 'Bearer '+auth.token,
			'Content-Type': 'multipart/form-data'
		}
	};

	if (auth.token.length > 3) {
		return headers;
	} else {
		return {};
	}
}

function unLoggedFormDataHeader() {
	return {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	};

}