import api from '@/services/api/index.js'
import SecureLS from '@/plugins/secureLS.js'
import router from "../../router";
import {parseJWT} from "@/services/api/JWTParser";
import Toast from "@/services/swal2/mixins";

const token = SecureLS.get(btoa('token'));
const state = token
	? {
		status: {loggedIn: true},
		token,
		request: {},
		askResetPasswordLinkIsLoading: false,
		resetPasswordMailSent: false,
		isResetTokenValid: false,
		connexionIsLoading: false
	}
	: {
		status: {},
		token: null,
		request: {},
		askResetPasswordLinkIsLoading: false,
		resetPasswordMailSent: false,
		isResetTokenValid: false,
		connexionIsLoading: false
	};


const mutations = {
	AUTHENTICATE: (state, token) => {
		state.status = {loggedIn: true};
		state.token = token;
		state.request = {};
	},
	LOGOUT: (state) => {
		state.status = {loggedIn: false};
		state.request = {};
		state.token = null;
	},
	CONNEXION_IS_LOADING_STATUS: (state) => {
		state.connexionIsLoading = !state.connexionIsLoading
	},
	BAD_AUTHENTICATE: (state, requestStatus) => {
		state.status = {loggedIn: false};
		state.token = null;
		state.request = requestStatus[0]
	},
	RESET_PASSWORD_MAIL: (state) => {
		state.resetPasswordMailSent = true;
	},
	VALIDATE_TOKEN: (state, isValid) => {
		state.isResetTokenValid = isValid;
	},
	VALIDATE_RESET: (state) => {
		state.isResetSuccess = true;
	}
};
const actions = {
	logMeIn: async (store, authenticate) => {
		store.commit('CONNEXION_IS_LOADING_STATUS');
		await api.authentication.authenticate(authenticate[0], authenticate[1])
			.then( response =>  {
				if (response.data){
					let JWTToken = response.data.token
					let parsedToken = parseJWT(response.data.token)

					let tokenDate = new Date(parsedToken.exp * 1000)
					let timestampedToken = {
						token:JWTToken,
						timestamp:tokenDate
					}

					store.commit('AUTHENTICATE', timestampedToken);
					SecureLS.set(btoa('token'), timestampedToken);
					SecureLS.set('currentUser', {email:parsedToken.email,roles:parsedToken.roles});
					Toast.fire({
						icon: 'success',
						title: 'Welcome to the Air Quality Dashboard',
						timer: 1500
					}).then(() => {
						router.push('/')
						store.commit('CONNEXION_IS_LOADING_STATUS');
					})

				} else {
					store.commit('CONNEXION_IS_LOADING_STATUS');
					const requestStatus = [response.data.message.message];
					store.commit('BAD_AUTHENTICATE', requestStatus);
				}


			})
			.catch(error => {
				store.commit('CONNEXION_IS_LOADING_STATUS');
				const requestStatus = [error.response];
				store.commit('BAD_AUTHENTICATE', requestStatus);
			});
	},
	logout: (store) => {
		store.commit('LOGOUT')
		SecureLS.removeAll()
		setTimeout(() => {
			router.push('/login')
		}, 1000)
	},
	askForPasswordReset: async (store, data) => {
		await api.authentication.resetPassword(data)
			.then(() => {
				store.commit('RESET_PASSWORD_MAIL')
				Toast.fire({
					icon: 'success',
					title: 'A code has been sent to you by e-mail (remember to check your junk e-mail). Once received, enter the code indicated in the field below',
				}).then( () => {
					setTimeout(() => {
						router.push('reset-password')
					}, 2000)
				})
			})
			.catch(error => {
				store.commit('CONNEXION_IS_LOADING_STATUS');
				const requestStatus = [error.response.data];
				store.commit('BAD_AUTHENTICATE', requestStatus);
			});
	},
	confirmToken: async (store, data) => {
		await api.authentication.confirmResetPasswordToken(data)
			.then(() => {
				store.commit('VALIDATE_TOKEN', true)
				Toast.fire({
					icon: 'success',
					title: 'The verification code is correct',
				})
			} )
			.catch(() => {
				store.commit('VALIDATE_TOKEN', false);
				Toast.fire({
					icon: 'error',
					title: 'The verification code is incorrect',
				})
			});
	},
	resetPassword: async (store, data) => {
		await api.authentication.sendNewPassword(data)
			.then(() => {
				Toast.fire({
					icon: 'success',
					title: 'Your password has been updated',
				}).then(
					setTimeout( () => router.push({ path: '/login'}), 2000)
				)
			})
			.catch(error => {
				store.commit('CONNEXION_IS_LOADING_STATUS');
				const requestStatus = [error.response.data];
				store.commit('BAD_AUTHENTICATE', requestStatus);
				Toast.fire({
					icon: 'error',
					title: 'An error occurred while updating your password',
				})
			});
	},
	checkHealth: async () => {
		await api.authentication.healthCheck()
			.then(() => {
				console.log('Health API OK')
			})
			.catch(() => {
				router.push({name: 'error', params: {type: 'maintenance'}});
			})
	}
};

const getters = {
	authStatus: state => {
		return state.request
	},
	getAskResetPasswordLinkIsLoading: state => {
		return state.askResetPasswordLinkIsLoading
	},
	resetMailSent: state => {
		return state.resetPasswordMailSent
	},
	getConnexionIsLoading: state => {
		return state.connexionIsLoading
	},
	getIsResetTokenValid: state => {
		return state.isResetTokenValid;
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
