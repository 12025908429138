import api from '@/plugins/axios'
const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
	getReports,
}

function getReports(data) {
	return api.get('/loacreports?'+querystring.stringify(data),authHeader.classicalHeader())
}

