import api from '@/services/api/index.js'
import Vuex from "@/store";

const state = {
	reports: {},
	weatherReports:{},
	loadingStatus: false,
	request: {
		data:{
			sensorId:null,
			pollenType: null
		},
		error: {}
	},
};

const mutations = {
	SET_REPORTS: (state, reports) => {
		state.loadingStatus = true
		state.reports.pmConc = reports.pmConcentration;
		state.reports.altiConc = reports.altitudeConcentration;
		state.reports.yearlyConc = reports.yearConcentration;
		state.loadingStatus = false
	},
	BAD_REQUEST: (state, requestStatus) => {
		state.request.error = {status: requestStatus[1], message: requestStatus[0]}
	},
	SET_CHOSEN_SENSOR: (state, sensorId) => {
		state.request.data.sensorId = sensorId;
	},
	REPORTS_IS_LOADING_STATUS: (state) => {
		state.loadingStatus = !state.loadingStatus
	},
};

const actions = {
	setReports: async (store,data) => {
		await api.report.getReports(data)
			.then( response =>  {
				// store.commit('REPORTS_IS_LOADING_STATUS');
				store.commit('SET_REPORTS', response.data.data);
			})
			.catch(error => {
				if (error.response.status === 401) {
					Vuex.dispatch('login/logout')
				}
				const requestStatus = [error.message,error.error_code];
				store.commit('BAD_REQUEST', requestStatus);
			});
	}
};

const getters = {
	reports: state => {
		return state.reports
	},
	loadingStatus : state => {
		return state.loadingStatus
	},
	getRequest : state => {
		return state.request
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}