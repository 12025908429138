import authentication from './authentication.js'
import sensor from "./sensor";
import report from "./report";
import risk from "./risk";

export default {
	authentication,
	sensor,
	report,
	risk
}
