import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import sensor from "./modules/sensor/sensor";
import report from "./modules/report/report";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		login,
		sensor,
		report,
	},
	state: {
		packageVersion: process.env.VUE_APP_VERSION,
	},
	getters: {
		appVersion: (state) => {
			return state.packageVersion
		},
	}
});

export default store

