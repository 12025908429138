import Vue from 'vue'
import Router from 'vue-router'
import secureLS from '../plugins/secureLS.js'

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
            meta: {requiresAuth: true}
        },
        {
            path: '/login',
            name: 'login',
            meta: {requiresAuth: false},
            component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            meta: { layout: "NoSidebar", requiresAuth : false},
            component: () => import(/* webpackChunkName: "about" */ '@/views/ResetPassword.vue'),
        },
        {
            path: '*',
            name: 'error',
            props: true,
            meta: {requiresAuth: false},
            component: () => import(/* webpackChunkName: "about" */ '@/views/Error.vue'),
        },

    ],
    // eslint-disable-next-line
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return {x: 0, y: 0}
        }
    },
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/error', '/reset-password'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = secureLS.get(btoa('token'));

    window.document.title = to.meta && to.meta.title ? to.meta.title : 'LifyAir LOAC Dashboard';

    if (to.name !== 'error') {
        if (authRequired && !loggedIn) {
            return next('/login');
        } else if (loggedIn.token && !authRequired) {
            return next('/');
        }
    }
    next();
});

export default router;
